import React, {useEffect, useState} from "react";

import FindMyMatch2 from '../../components/FindMyMatch2';
import Layout from "../../components/layout";
import "./find-my-match-new.css";
import "../../components/myCards/styles/styles.css";


function FindMyMatchPage(){

    useEffect( () => {
        sessionStorage.removeItem("cardsForMe")
    } , [] );

    return (
    <Layout>
        <div className="find-my-match-container">
        
            <FindMyMatch2 />
            
        </div>
    </Layout>
    );
}

export const Head = () => (
    <React.Fragment>
      <title>Find my card</title>
      {/* <meta> Find the right Credit Card for you </meta> 
      <meta> Finding Credit Card just made Easy </meta>  */}
    </React.Fragment>);

export default FindMyMatchPage;