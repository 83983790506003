import React from "react";
import "./styles.css";

function Option(props){
    const {type , value, changeFn , name ,id  , label , isChecked}  = props; 
    return (
        <div 
            key={`${type}-${value}-${name}-${isChecked}`}
            className="option-details" 
            onClick={changeFn}
            value={value}
            id={id}>
            {isChecked ? 
                <input className="input-style" id={id} type={type} value={value} checked name={name} /> : 
                <input className="input-style" id={id} type={type} value={value} checked={isChecked} name={name}/>
            }        
            <span value={value} id={id} style={{marginLeft:"0.5rem"}} className="input-label"> {label} </span>
        </div>)
}

export default Option;