import React from "react";
import "./styles.css";


function IncomeAndAge(props){
    const {formData = {}, changeFn} = props;
    return (
        <div className="income-and-age-containter">
    
            <div className="income-input-container">
                <span className="prefix"><i className="rupee-icon"></i></span>
                <input 
                    style={{fontSize : "1rem"}}
                    onChange={changeFn}
                    value={formData.income} 
                    type="number" 
                    />
                <span className="suffix">/year</span>
            </div>

        </div>
    )
}


export default IncomeAndAge;