import React, { useEffect, useState } from "react";
import Switch from "./switch";
import Slider from "./slider";

import {filterInputValue} from '../../../utilities/utilfns';

import "./styles/index.css";


function SpendsContainer(props){
    const { metadata = {} , userInput = {} } = props;
    const [maxValue, setMaxValue] = useState(50000);
    const [selectedSpendType, setSelectedSpendType] = useState("monthly");
    const [value, setValue] = useState(0);

    function changeSpendType(){
        const newSpendType =  selectedSpendType === "monthly" ? "annual" : "monthly";
        const newMaxValue = newSpendType === "monthly" ? 50000 : 600000;
        let updatedValue = newSpendType === "monthly" ? Math.ceil(value/12) : value*12;
        updatedValue = filterInputValue(updatedValue,newMaxValue);
        setValue( updatedValue );
        setMaxValue( newMaxValue );
        setSelectedSpendType( newSpendType);
        props.setValues( { id: metadata.key, spendType:"monthly" , value : updatedValue  } )
    }

    useEffect( () => {
        setValue(userInput["value"]);
        setValue(isNaN(userInput["value"]) ||  Number(userInput["value"]) < 0 ? 0 : userInput["value"]);
        setSelectedSpendType(userInput["spendType"]);
    } , [userInput] )
    
    function inputChange(ev){
        ev.preventDefault();
        const value = ev.target.value;
        const updatedValue = filterInputValue(value,maxValue);
        setValue(updatedValue);
        props.setValues( { id: metadata.key,  spendType: selectedSpendType, value : updatedValue  } )
    }

    return (
        <div key={metadata.key} className="spend-category-container">
            <div className="header-container" key={ "header" + metadata.key}>
                <div className="spends-category-title-container">
                    <img className="icon-image" src={metadata.icon} alt={metadata.displayName} />
                    <div className="label"> {metadata.displayName}  </div> 
                </div>
                <div className="input-spend">
                    <span class="prefix"><i class="rupee-icon"></i></span>
                    <input 
                        style={{border:"none",width: "100%", fontSize : "1rem"}}
                        type="number"
                        max={maxValue}
                        key={ "input" +  metadata.key} 
                        id={"input-" + metadata.key} 
                        value={value} 
                        onChange={inputChange}
                    />
                </div>
                {/* <div className="spends-type-container">
                    <div className={selectedSpendType === "monthly" 
                        ? "bold" : "regular"}> Monthly </div>
                    <Switch 
                        key={"switch" + metadata.key}
                        switchId={metadata.key}
                        changeSpendType={changeSpendType} 
                        defaultValue={selectedSpendType} />
                    <div className={selectedSpendType === "annual" 
                        ? "bold" : "regular"}> Annually </div>
                </div> */}
                    
            </div>
            <div className="body-container">
                
                <Slider  
                    key={ "slider" +  metadata.key}  
                    id={metadata.key}
                    iconImg={metadata.icon}
                    value={Number(value)}
                    maxValue={maxValue}
                    inputChange={inputChange}/> 
            </div>
            <div className="input-range"> 
                <div> <i class="rupee-icon"></i> {"0"} </div>  
                <div> <i class="rupee-icon"></i> {"50k"} </div>
            </div> 
        </div>
    )
}

export default SpendsContainer;


