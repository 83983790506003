import React from "react";
import "./styles/slider.css";



function Slider(props){
    const {  value , maxValue, id} = props;  
    return (
        <input 
            type="range" 
            min={0} 
            step={500}
            max={maxValue} 
            className="slider"
            defaultValue={0}
            value={value}
            style={{ fontSize : "1rem" ,backgroundSize : ((value*100)/maxValue + "%") + " 100%"}}
            onChange={props.inputChange}
        />
    )

}

export default Slider;