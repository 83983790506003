import React, { Fragment, useEffect, useState } from "react";
import { navigate } from 'gatsby';
import ProgressBar from '../ProgressBar';
import Option from "../FindMyMatch/option";
import SpendsContainer from '../FindMyMatch/spendsContainer';
import IncomeAndAge from './incomeAndAge';
import { stages , nonSpendsInput , spendCategories, whyApplyViaUs } from "./constants"; 

import "../../pages/find-my-match/find-my-match-new.css"


import "../FindMyMatch/styles.css";
import { sendEventToAnalytics } from "../../utilities/tracker";

function FindMyMatch(){
    const [activeFormStage, setActiveFormStage] = useState(stages[0]);
    const [formData, setFormData] = useState(getInitialData());
    const [disableNxt , setNxtDisable] = useState(true);
    const [disableBack, setBackDisable] = useState(true);

    useEffect( () => {
        const userSpending = JSON.parse(sessionStorage.getItem("userSpending"));
        if(userSpending){
            const employmentType = userSpending.employmentType;
            const complimentaryBenefits = { ...userSpending.complimentaryBenefits };
            const creditScore = userSpending.creditScore;
            const income = userSpending.income;
            const userSpends = userSpending.userSpends;
            setFormData({
                income,
                creditScore,
                complimentaryBenefits,
                userSpends,
                employmentType
            })
        }
    } , [] )

    function getCardsForMe(){
        sessionStorage.setItem("userSpending", JSON.stringify(formData));
        navigate("/find-my-match/cards-for-me" + window.location.search);
    }

    function sendToGA(activeStageDetails){
        const eventName = "form_data_" + activeStageDetails.name;
        const eventLabel =  activeStageDetails.name;
        const eventAction = "Form Step Completed";
        const eventConfig = { values :  formData[activeStageDetails.name]}
        const gaEventPayload = {
            eventName,
            eventLabel,
            eventAction,
            eventConfig,
        }
        sendEventToAnalytics(gaEventPayload);
    }

    useEffect(() => {
        // if(activeFormStage.name === "employmentType" && formData[activeFormStage.name] === "student"){
        //     setFormData({...formData, "income" : 0 , "creditScore" : 0 });
        //     setActiveFormStage( stages[activeFormStage.ind + 3] );
        // }
        setBackDisable(false);
        enableNtxBtn();
    } , [formData])

    useEffect(() => {
        enableNtxBtn()
    } , [activeFormStage])

    function enableNtxBtn(){
        if(( !formData[activeFormStage.name] && activeFormStage.name != "complimentaryBenefits") || (
            activeFormStage.name === "userSpends" && Object.keys(formData["userSpends"]).length == 0)
            || (activeFormStage.name === "userSpends" && Object.keys(formData["userSpends"]).length > 0 &&
            Object.keys(formData["userSpends"]).filter(ele => formData["userSpends"][ele].value > 0 ).length == 0
            )){
                if(formData[activeFormStage.name] === 0 && activeFormStage.name === "creditScore"){
                    setNxtDisable(false);
                }else{
                    setNxtDisable(true);
                }
        }else{
            setNxtDisable(false);
        }
    }

    function isOptSelected(formName, value , type){
        if(type == "checkbox"){
            return formData[formName]?.[value]
        }else{
            return formData[formName] === value
        }
    }

    function updateFormData(key, value) {
        setFormData({...formData, [key] : value});
    }

    function setInput(spendData){
        setFormData({...formData, userSpends : { ...formData.userSpends, [spendData.id] : spendData } })
    }


    return (
        <div className="fmm-container">
            <div className="fmm-sub-container">
            <ProgressBar value={activeFormStage.percentComplete} />
            <div className="find-my-match-header-desktop">
                <div className="find-my-match-pg-title"> {"You are seconds away from your perfect card."} </div>
                <div className="find-my-match-pg-subtitle"> {"Find the perfect credit card tailored for your need in 60 seconds!"} </div>
            </div>
            { activeFormStage.ind == 0 &&   <div className="find-my-match-header">
                <div className="find-my-match-pg-title"> {"You are seconds away from your perfect card."} </div>
                <div className="find-my-match-pg-subtitle"> {"Find the perfect credit card tailored for your need in 60 seconds!"} </div>
            </div> }
            <div className="find-my-match-user-inp-container">
                <div>
                <div className="user-inp-header">
                    <div className="user-inp-stage-title"> {activeFormStage.label}  </div>
                    { activeFormStage.subtitle && (<div>  {activeFormStage.subtitle}  </div>)  } 
                </div>
                <div className="user-inp-body">
                    { activeFormStage.name === "userSpends" ?  
                    (
                        spendCategories.map( item => <SpendsContainer 
                            key={item.key} 
                            metadata={item} 
                            setValues={setInput}
                            userInput={formData?.[activeFormStage.name]?.[item.key] || {}}
                            /> 
                        )
                    )
                    :  !(activeFormStage.name === "income") ? (
                        <div> 
                            {nonSpendsInput[activeFormStage.name].values.map( ele => (
                                <Option 
                                    type={nonSpendsInput[activeFormStage.name].type}
                                    value={ele.value}
                                    name={activeFormStage.name}
                                    id={ele.value}
                                    label={ele.label}
                                    isChecked={isOptSelected(activeFormStage.name,ele.value,nonSpendsInput[activeFormStage.name].type)} 
                                    changeFn={ (ev) => {
                                        const type = nonSpendsInput[activeFormStage.name].type;
                                        ev.preventDefault();
                                        const value = ele.value;
                                        if(type === "checkbox"){
                                            updateFormData(activeFormStage.name, { ...formData[activeFormStage.name], [ele.value] : !formData?.[activeFormStage.name]?.[ele.value] })
                                        }else{
                                            updateFormData(activeFormStage.name,  value)
                                        }
                                    } }
                                />
                            ) )}
                        </div>)  
                       : <IncomeAndAge  
                            formData={formData} 
                            changeFn={ (ev) => {
                                ev.preventDefault();
                                const value = ev.target.value;
                                updateFormData( "income",  value > 0 ? value : "" )
                            } }/>
                    }
                    </div>
                </div>
                <div 
                    className="user-inp-footer" 
                    style={{ justifyContent : activeFormStage.name == "employmentType" ?  "flex-end"  : "space-between"  }}
                    onClick={ev => {
                    ev.preventDefault();
                        const id = ev.target.id;
                        const currentStageInd = activeFormStage.ind;
                        const nextStageInd = id === "back" ? currentStageInd == 0 ? 0 : currentStageInd-1 
                            : currentStageInd == (stages.length - 1) ? currentStageInd : currentStageInd + 1;
        
                        if( id === "next" && !disableNxt && formData[activeFormStage.name] != undefined ){
                            if(formData["employmentType"] === "student"){
                                setActiveFormStage(stages[3]);
                            }
                            sendToGA(stages[currentStageInd]);
                            setActiveFormStage(  stages[nextStageInd] );
                            setBackDisable(false);
                            setNxtDisable(true);
                        }
                        else if( id ==="back" && activeFormStage.name === "userSpends"  && formData["employmentType"] === "student" ){
                            setActiveFormStage(  stages[0] );
                        }
                        else if(id === "back" && !disableBack){
                            setActiveFormStage(  stages[nextStageInd] );
                        }
                        window.scrollTo(0,0);
                        if(currentStageInd === (stages.length -1 ) && !disableNxt){
                            getCardsForMe()
                        }
                }}>
                    {activeFormStage.name != "employmentType" && (
                        <div className={`find-my-match-form-btns ${disableBack ? 'disable-btn' : ''  }`} id="back" > {"Back"}  </div>
                    )}
                    <div className={`find-my-match-form-btns ntx-btn ${disableNxt ? 'disable-btn' : ''} `} id="next"> {"Next"} </div>
                </div>

            </div>

            <div className="why-apply-via-us">
                {whyApplyViaUs.map( ele => (
                    <div className="apply-reason">
                        <img alt="apply-reason" src={ele.icon} className="apply-reason-img"/>
                        <div> {ele.text} </div>
                    </div>
                ) )}
                

            </div>
        </div>
    </div>)
}


function getInitialData(){
    return (
        {
            income : null,
            userSpends : spendCategories.reduce( (acc, ele) => { 
                acc[ele.key] = {value : 0 , key : ele.key, spendType : "monthly"}
            return acc; } , {} ),
            employmentType : null,
            complimentaryBenefits : null,
            creditScore : null
        }
    )
}


export default FindMyMatch;