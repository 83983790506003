export const spendCategories = [
    {
        key : "e-commerce",
        displayName : "E-commerce",
        icon: "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/black/e-commerce.svg"
    },
    {
        key : "food-ordering",
        displayName : "Food Ordering",
        icon:"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/black/food-ordering.svg"
    },
    {
        key : "flights-and-hotels",
        displayName : "Flights and Hotels",
        icon: "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/black/flight-and-hotels.svg"
    },
    {
        key : "utilities",
        displayName : "Utilities",
        icon:"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/black/utilities.svg"
    },
    {
        key : "fuel",
        displayName : "Fuel",
        icon: "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/black/fuel.svg"
    },
    {
        key : "insurance",
        displayName : "Insurance",
        icon:"https://credzyimages.s3.ap-south-1.amazonaws.com/icons/black/insurance.svg"
    },
    {
        key : "offline",
        displayName : "Offline",
        icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/black/offline.svg"
    },
    {
        key : "other-spends",
        displayName : "Other Spends",
        icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/icons/black/other-spends.svg"
    },
]


export const stages = [ 
    {
        name : "employmentType",
        label :  "My Employment status is :",
        percentComplete : 10,
        ind : 0,
    },
    {
        name : "income", 
        label : "What is your annual income ?",
        percentComplete : 30,
        ind : 1,
    },
    {
        name : "creditScore",
        label : "What is your credit score range?",
        percentComplete : 60,
        ind : 2,
    },
    {
        name : "userSpends", 
        label : "What are your Monthly spends?",
        subtitle : "Enter an estimate of your spends across categories.",
        percentComplete : 90,
        ind : 3,
    },
    {
        name : "complimentaryBenefits",
        label : "Select the Required Benefits",
        percentComplete : 100,
        ind : 4
    }
 ]

export const nonSpendsInput = {
    "employmentType" :  {
        type : "radio",
        values : [
            {
                value : "salaried",
                label : "Salaried",
            },
            {
                value : "self-employed",
                label : "Self Employed"
            },
            {
                value : "student",
                label : "Student"
            },
            {
                value : "other",
                label : "Other",
            }
        ]    
    }, 
    "creditScore" : {
        type : "radio",
        values : [ 
            {
                label : "Below 700",
                value : 680
            },
            {
                label : "700 - 750",
                value : 725
            },
            {
                label : "750 - 780",
                value : 765
            },
            {
                label : "Above 780",
                value : 800
            },
            {
                label : "New to credit",
                value : 0
            }
        ]
    },
    "complimentaryBenefits" : {
        type : "checkbox", 
        values : [
            {
                label : "Domestic Lounge",
                value : "domestic-lounge"
            },
            {
                label : "International Lounge",
                value : "international-lounge"
            },
            {
                label : "Golf Rounds",
                value : "golf"
            },
        ]
    }
}


export const whyApplyViaUs = [
    {
        icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/approval-chance.svg",
        text : "Over 90% approval Rate." 
    },
    {
        icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/credit-score.svg",
        text : "No Impact on your credit score." 
    },
    {
        icon : "https://credzyimages.s3.ap-south-1.amazonaws.com/static-images/no-spam.svg",
        text : "No Spam Policy." 
    },
];

