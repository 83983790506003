import React from 'react';
import "./styles.css";


function ProgressBar(props){
    const {value = 0} = props;
    
    return(
        <div className='progress-bar-container'>
            <input 
                type="range"
                disabled
                style={{
                    marginLeft:"0",
                    backgroundSize : (value + "%") + " 100%"
                }}
                value={value}
                className="progress-bar"
            />
        </div>
    )
}

export default ProgressBar;
